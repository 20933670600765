import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const ProductPriceTypography = ({type, price, on_offer}) => {
    const { templateSettings } = useSelector((state) => state.globalReducer);

    return (
        <>
            {!type || type == 'has_offer' && templateSettings.from_pricing == 'yes' && (
                <Typography component="span" className="fw-bold" color="secondary" sx={{fontSize:16}}>
                    From &nbsp;
                </Typography>
            )}
            <Typography component="span" className="fw-bold " color={on_offer ? "error" : "secondary"} sx={{fontSize:18}}>
                {price} 
            </Typography>
            {
                templateSettings.suffix_status == 'yes' && (
                    <Typography component="span" className="" color="secondary" sx={{fontSize:16}}>
                        {templateSettings.suffix_string}
                    </Typography>
                )
            }
        </>
    )
}

export default ProductPriceTypography